import moment from 'moment'
import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { DateTime } from 'luxon'
import MediaType from '@/common/enums/mediaTypeEnum'
import MemberPermissions from '@/common/enums/memberPermissionsEnum'
import TimeLimitCondition from '@/common/config'

const getFormDataFromJSON = object => {
  const formData = new FormData()
  Object.keys(object).forEach(key => {
    formData.append(key, object[key])
  })
  return formData
}
const getFormDataFromJSONImage = (object, index) => {
  const formData = new FormData()
  formData.append('file', object.file[index])
  formData.append('duration', object.duration[index])
  formData.append('thumbnail', object.thumbnail[index])
  formData.append('imageSrc', object.imageSrc[index])
  return formData
}
const userHaveRole = (user, role) => {
  if (user.user_has_role && user.user_has_role.length > 0) {
    // eslint-disable-next-line no-restricted-syntax
    for (const userHasRoleElement of user.user_has_role) {
      if (role.includes(userHasRoleElement.role.role)) {
        return true
      }
    }
  }
  return false
}
const getThumbnailOfFile = file => {
  if (file.type_main === MediaType.VIDEO) {
    if (file.thumbnail) {
      return file.thumbnail
    }
    // eslint-disable-next-line global-require
    return require('@/assets/images/simiicons/Video(1).svg')
  }
  return file.file
}
const getLightBoxOfMediaArray = items => {
  const selectedMedia = []
  const screenWidth = window.innerWidth // Get screen width

  // Set video dimensions based on screen width
  const videoWidth = screenWidth > 800 ? 800 : 425
  const videoHeight = screenWidth > 800 ? 600 : 320
  items.forEach(item => {
    if (item.type_main === MediaType.IMAGE) {
      const media = { // For an image
        type: 'image', // Can be omitted for image
        thumb: item.file,
        src: item.file,
        caption: `<h4>${item.name}</h4>`, // Optional
      }
      selectedMedia.push(media)
    } else {
      const media = { // For a video that can be played in the <video> tag
        type: 'video',
        thumb: item.thumbnail,
        sources: [
          {
            src: item.file,
            type: item.type,
          },
        ],
        caption: `<h4>${item.name}</h4>`,
        width: videoWidth, // Required
        height: videoHeight, // Required
        autoplay: true, // Optional: Autoplay video when the lightbox opens
      }
      selectedMedia.push(media)
    }
  })
  return selectedMedia
}
const getFeatureNameByEnum = enumValue => {
  const result = Object.keys(MemberPermissions)
    .find(key => MemberPermissions[key] === enumValue)
  if (result) {
    return result.replaceAll('_', ' ').toLowerCase()
  }
  return ''
}
const getLowestTimeForTargetAd = () => {
  let minDateTimeCanBeSelected = moment.utc().add(TimeLimitCondition.targetAdPriorMinutes, 'minutes')
  minDateTimeCanBeSelected = minDateTimeCanBeSelected.add(5 - (minDateTimeCanBeSelected.minutes() % 5), 'minutes')
  minDateTimeCanBeSelected.set('seconds', 0)
  return minDateTimeCanBeSelected
}
const getLowestTimeForTargetAdEditAble = () => {
  let minDateTimeCanBeSelected = moment.utc().add(TimeLimitCondition.targetAdEditAblePriorMinutes, 'minutes')
  minDateTimeCanBeSelected = minDateTimeCanBeSelected.add(5 - (minDateTimeCanBeSelected.minutes() % 5), 'minutes')
  minDateTimeCanBeSelected.set('seconds', 0)
  return minDateTimeCanBeSelected
}
// For Target Ad Time Update By Admin Only for Testing
const getLowestUpdateTimeForTargetAd = () => {
  let minDateTimeCanBeSelected = moment.utc().add(5, 'minutes')
  minDateTimeCanBeSelected = minDateTimeCanBeSelected.add(5 - (minDateTimeCanBeSelected.minutes() % 5), 'minutes')
  minDateTimeCanBeSelected.set('seconds', 0)
  return minDateTimeCanBeSelected
}
const checkScrollIfonBottom = event => {
  const bottomOfWindow = event.target.scrollTop + event.target.clientHeight + 1 >= event.target.scrollHeight
  return bottomOfWindow
}
const showErrorMessage = statusCode => {
  if (statusCode === 401 || statusCode === 403) {
    return false
  }
  return true
}
const showToast = (title, text, variant = 'success', timeout = 2000) => {
  let icon = 'InfoIcon'
  if (variant === 'success') {
    icon = 'CheckCircleIcon'
  }
  if (variant === 'danger') {
    icon = 'AlertCircleIcon'
  }
  Vue.prototype.$toast({
    component: ToastificationContent,
    props: {
      icon,
      title,
      text,
      variant,
    },
  }, { timeout })
}
const confirmDeletion = async () => Vue.prototype.$swal({
  title: 'Are you sure?',
  text: "You won't be able to reverse this!",
  icon: 'warning',
  showCancelButton: true,
  confirmButtonText: 'Yes, delete it!',
  customClass: {
    confirmButton: 'btn btn-primary',
    cancelButton: 'btn btn-outline-danger ml-1',
  },
  buttonsStyling: false,
})
const createQueryString = params => Object.keys(params)
  .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
  .join('&')

const timeConverter = timestamp => moment(timestamp).utc().format('ddd, MMM Do YYYY, HH:mm:ss')

const formatDateToDDMMYYYY = dateString => {
  const [year, month, day] = dateString.split('-')
  return `${day}-${month}-${year}`
}

const calculateEndTime = (startTime, duration) => {
  if (!startTime) return null

  // Convert start time from HH:mm:ss to seconds
  const [hours, minutes, seconds] = startTime.split(':').map(Number)
  const startInSeconds = hours * 3600 + minutes * 60 + seconds

  // Add the duration to the start time
  const endInSeconds = startInSeconds + duration

  // Convert the result back to HH:mm:ss format
  const endHours = Math.floor(endInSeconds / 3600).toString().padStart(2, '0')
  const endMinutes = Math.floor((endInSeconds % 3600) / 60)
    .toString()
    .padStart(2, '0')
  const endSeconds = (endInSeconds % 60).toString().padStart(2, '0')

  return `${endHours}:${endMinutes}:${endSeconds}`
}
const findAuth0SessionKey = storage => {
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < storage.length; i++) {
    const currentKey = storage.key(i)

    // Check if the key starts with the expected prefix
    if (currentKey.startsWith('@@auth0spajs@@')) {
      return currentKey // Return the matching key
    }
  }
  return null // No matching key found
}

const getTimeFromDateTime = (datetime, timeZone) => {
  if (!datetime) return ''
  const dateInZone = DateTime.fromISO(datetime, { zone: timeZone })
  const hours = String(dateInZone.hour).padStart(2, '0')
  const minutes = String(dateInZone.minute).padStart(2, '0')
  const seconds = String(dateInZone.second).padStart(2, '0')
  return `${hours}:${minutes}:${seconds}`
}

const getDateFromDateTime = (datetime, timeZone) => {
  if (!datetime) return ''
  const dateInZone = DateTime.fromISO(datetime, { zone: 'utc' }).setZone(timeZone)
  const day = String(dateInZone.day).padStart(2, '0')
  const month = String(dateInZone.month).padStart(2, '0')
  const { year } = dateInZone
  return `${day}-${month}-${year}`
}

const ConvertDateTimeFromAnyTimeZoneToUTC = (dateTime, timeZone) => DateTime.fromISO(dateTime, { zone: timeZone }).toUTC().toISO()
const ConvertDateTimeToUTCFromAnyTimeZone = (dateTime, timeZone) => DateTime.fromISO(dateTime, { zone: 'utc' }).setZone(timeZone).toISO()

export {
  getFormDataFromJSON,
  getFormDataFromJSONImage,
  userHaveRole,
  getLightBoxOfMediaArray,
  getThumbnailOfFile,
  getFeatureNameByEnum,
  getLowestTimeForTargetAd,
  getLowestTimeForTargetAdEditAble,
  getLowestUpdateTimeForTargetAd,
  showErrorMessage,
  showToast,
  confirmDeletion,
  timeConverter,
  createQueryString,
  checkScrollIfonBottom,
  formatDateToDDMMYYYY,
  calculateEndTime,
  findAuth0SessionKey,
  getTimeFromDateTime,
  getDateFromDateTime,
  ConvertDateTimeFromAnyTimeZoneToUTC,
  ConvertDateTimeToUTCFromAnyTimeZone,
}
