export default [
  {
    path: '/Brands',
    name: 'brands',
    component: () => import('@/views/brands/index.vue'),
    meta: {
      pageTitle: 'All Brands',
      breadcrumb: [
        {
          text: 'brands',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
]
