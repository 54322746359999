export default [
  {
    path: '/agency-revenue-share',
    name: 'agency-revenue-share',
    component: () => import('@/views/admin/agencyRevenueShare/index.vue'),
    meta: {
      pageTitle: 'Agency Revenue Share',
      breadcrumb: [
        {
          text: 'Agency Revenue Share',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
]
