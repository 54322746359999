export default [
  {
    path: '/revenue-sharing',
    name: 'revenue-sharing',
    component: () => import('@/views/revenueSharing/index.vue'),
    meta: {
      pageTitle: 'Revenue Sharing',
      breadcrumb: [
        {
          text: 'Revenue Sharing',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
]
