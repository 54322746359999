export default [
  {
    path: '/venue-revenue-share',
    name: 'venue-revenue-share',
    component: () => import('@/views/admin/pmpRevenueShare/index.vue'),
    meta: {
      pageTitle: 'Venue Revenue Share',
      breadcrumb: [
        {
          text: 'Venue Revenue Share',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
]
