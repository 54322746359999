export default [
  {
    path: '/pmp-ads',
    name: 'pmp-ads',
    component: () => import('@/views/pmpAds/index.vue'),
    meta: {
      pageTitle: 'External Ads',
      breadcrumb: [
        {
          text: 'External Ads',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/pmp-ads/add',
    name: 'pmp-ads-add',
    component: () => import('@/views/pmpAds/CreatePmpAds.vue'),
    meta: {
      navActiveLink: 'pmp-ads',
      pageTitle: 'Add External Ad',
      breadcrumb: [
        {
          text: 'External Ads',
          to: '/pmp-ads',
        },
        {
          text: 'Add External Ad',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/pmp-ads/edit-pmp-ads/:id',
    name: 'edit-pmp-ads',
    component: () => import('@/components/pmp-ads/EditPmpAds.vue'),
    meta: {
      navActiveLink: 'pmp-ads',
      pageTitle: 'Edit External Ad',
      breadcrumb: [
        {
          text: 'External Ads',
          to: '/pmp-ads',
        },
        {
          text: 'Edit External Ad',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/pmp-ads/view/:user_type/:id',
    name: 'view-pmp-ads',
    component: () => import('@/views/pmpAds/ViewPmpAd.vue'),
    meta: {
      navActiveLink: 'pmp-ads',
      pageTitle: 'View External Ad',
      breadcrumb: [
        {
          text: 'External Ads',
          to: '/pmp-ads',
        },
        {
          text: 'View External Ad',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/pmp-ads/detail/:id/analytics',
    name: 'pmp-ads-scheduler-analytics',
    component: () => import('@/views/pmpAds/PmpAdSchedulerAnalytics.vue'),
    meta: {
      navActiveLink: 'pmp-ads-scheduler-analytics',
      pageTitle: 'Scheduler',
      breadcrumb: [
        {
          text: 'External Ads',
          to: '/pmp-ads',
        },
        {
          text: 'Scheduler',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/device/:id',
    name: 'single-device-user',
    component: () => import('@/views/viewSinglePageScreen.vue'),
    meta: {
      pageTitle: 'Screen Details',
      breadcrumb: [
        {
          text: 'External Ads',
          to: '/pmp-ads',
        },
        {
          text: '',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
]
