import customViews from '@/router/pages/admin/customViews'
import users from '@/router/pages/admin/users'
import nonConfiguredScreen from '@/router/pages/admin/nonconfigured'
import businessTrial from '@/router/pages/admin/businessTrial'
import screenList from '@/router/pages/admin/screenList'
import pmpAdsList from '@/router/pages/admin/pmpAdsList'
import pmpAdRefund from './pmpAdRefund'
import pmpRevenueShare from './pmpRevenueShare'
import agencyRevenueShare from './agencyRevenueShare'

const adminRoutes = [
  ...users,
  ...customViews,
  ...nonConfiguredScreen,
  ...businessTrial,
  ...screenList,
  ...pmpAdsList,
  ...pmpAdRefund,
  ...pmpRevenueShare,
  ...agencyRevenueShare,
]

export {
  // eslint-disable-next-line import/prefer-default-export
  adminRoutes,
}
